import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const EndOfProjectBanner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.banner}>{t('endOfProjectBanner.description')}</div>
  );
};

export default EndOfProjectBanner;

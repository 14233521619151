import { ArrowLeftOutlined } from '@ant-design/icons';
import Routes from 'business/router/routes';
import React from 'react';
import Button from 'ui/button';
import navBack from 'business/router/navBack';
import ConnectedLayout, { ConnectedLayoutProps } from '../connected';
import styles from './index.module.scss';

interface GoBackProps {
  goBack: Routes | string;
}

const GoBack: React.FC<GoBackProps> = ({ goBack }) => {
  return (
    <Button
      loading={false}
      onClick={() => navBack(goBack)}
      className={styles.goBackButton}
    >
      <ArrowLeftOutlined />
    </Button>
  );
};

interface SubPageLayoutProps extends ConnectedLayoutProps {
  goBack: Routes | string;
}

const SubPageLayout: React.FC<SubPageLayoutProps> = ({
  children,
  goBack,
  ...connectedLayoutProps
}) => {
  return (
    <ConnectedLayout {...connectedLayoutProps}>
      <GoBack goBack={goBack} />
      {children}
    </ConnectedLayout>
  );
};

export default SubPageLayout;

import React from 'react';
import Layout from 'ui/layout';
import styles from './index.module.scss';

const DisconnectedLayout: React.FC = ({ children }) => {
  return (
    <div className={styles.disconnectedContainer}>
      <Layout mode="dark" isMenuVisible={false}>
        {children}
      </Layout>
    </div>
  );
};

export default DisconnectedLayout;

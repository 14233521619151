import AuthLayout from 'business/user/layout/auth';
import React from 'react';
import NotConnectedCard from 'business/user/components/notConnectedCard';
import Space from 'ui/space';
import AppLogo from 'ui/appLogo';
import Typography from 'ui/typography';
import { useTranslation } from 'react-i18next';
import { useZendesk } from 'technical/hooks/useZendesk';
import { Link } from 'react-router-dom';
import Routes from 'business/router/routes';
import Button from 'ui/button';
import config from 'config';

const SSOErrorPage: React.FC = () => {
  const { t } = useTranslation();
  if (config.zendesk.enabled) {
    useZendesk(); // we use it exceptionnally here because it's the only not connected page where we need to have the assistance displayed
  }

  return (
    <AuthLayout>
      <NotConnectedCard>
        <Space direction="vertical" align="center">
          <AppLogo />
          <Typography.Title level={3}>
            {t('errors.sso_connection_error_title')}
          </Typography.Title>

          <Typography.Paragraph center>
            {t('errors.sso_connection_error_details')}
          </Typography.Paragraph>

          <Link to={Routes.SsoSignIn}>
            <Button>{t('errors.sso_connection_go_back')}</Button>
          </Link>
        </Space>
      </NotConnectedCard>
    </AuthLayout>
  );
};

export default SSOErrorPage;
